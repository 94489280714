.header-nav {
  width: 100%;

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item a {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6em;
    line-height: 100%;
    color: $color-nav--top;
  }
}
