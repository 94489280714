.swiper-slide {
  position: relative;

  &__box {
    position: absolute;
    top: 17em;
    left: 50%;
    z-index: 2;

    transform: translateX(-50%);

    & > h2 {
      width: 100%;
      max-width: 22em;

      font-style: normal;
      font-weight: 600;
      font-size: 3.6em;
      line-height: 42px;
      font-family: $font-family;
      text-align: center;
      color: $color-text--white;
    }

    & > p {
      width: 100%;
      max-width: 41em;
      margin-bottom: 1.8em;

      font-style: normal;
      font-weight: 600;
      font-size: 1.8em;
      line-height: 22px;
      font-family: $font-family;
      text-align: center;
      color: $color-text--white;
      text-shadow: 10px 10px 50px #1261b0;
    }
  }
}

.more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 11.125em;
  height: 5em;
  margin: 0 auto;

  font-style: normal;
  font-weight: 600;
  font-size: 1.6em;
  line-height: 24px;
  font-family: $font-family;
  color: $color-nav--top;
  text-transform: uppercase;

  background: $bg--button;
  border-radius: 1em;
}

.first-slider {
  position: relative;
}

.first-slider__button {
  position: absolute;
  top: 50%;
  z-index: 30;

  transform: translateY(-50%);

  svg {
    fill: transparent;
  }

  &--next {
    right: 5em;
    transform: scaleX(-1) translateY(-50%);
  }

  &--prev {
    left: 5em;
  }
}
