.burger-btn {
  display: none;
  width: 30px;
  flex-direction: column;
  grid-gap: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 105;

  span {
    width: 100%;
    height: 3px;
    background-color: #006DDA;
    transition: all .4s;
  }

  &.active {

    span:last-child {
      transform: rotate(45deg) translateY(0);
    }

    span:first-child {
      transform: rotate(-45deg) translateY(5px) translateX(-5px);
    }

    span:nth-child(2) {
      opacity: 0;
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.mobile-box {
  display: none;
}

@media screen and (max-width: 768px) {

.mobile-box {
  display: block;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 350px;
  top: 0;
  left: 0;
  transform: translateX(-150%);
  height: 100vh;
  background-color: rgba(19, 19, 19, 0.95);
  transition: all .4s;
  box-shadow: -10px 0px 9px 20px rgba(19, 19, 19, 0.95);

  &.active {
    transform: translateX(0);
  }


  .header-nav {
    display: flex;


    ul {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 15px;
    }
  }

  .tell-list {
    margin-top: 40px;
    display: flex;
    grid-gap: 15px;
  }

  .header-inner__form {
    display: flex;
    margin-top: 50px;

    input {
      color: #fff;
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 220%;
      padding: 0;
      /* or 35px */

      &::placeholder {
        font-size: 14px;
      }
    }
  }

}

}
