.wave-box {
  position: absolute;
  top: -14.8em;
  z-index: 1;

  width: 9999vw;
  height: 15em;

  background-image: url(../img/wave.svg);
  background-repeat: repeat-x;
  background-size: contain;

  animation: waves 30s infinite ease-in-out;
}


@keyframes waves {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -300vw;
  }

  100% {
    margin-left: 0;
  }
}
