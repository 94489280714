.tell-list {
  display: flex;
  flex-direction: column;
  grid-gap: 0.7em;

  &__item a {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6em;
    line-height: 110%;
    color: $color-nav--top;
  }
}
