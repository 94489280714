.about-sec {
  padding: 10em 0;
  background-color: $bg--about;
  position: relative;

  &--page {
    padding: 0 0 10em 0;
    margin-top: 12em;
  }
}


.about-box {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 13em;

  &__descr {
    max-width: 37em;
    width: 100%;

    & > h2 {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 3.2em;
      line-height: 130%;
      width: fit-content;
      color: $color-text--white;
      margin-bottom: 1.9em;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        max-width: 136px;
        width: 80%;
        height: 5px;
        background-color: #fff;
        left: 0;
        bottom: -10px;
      }
    }

    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 1.4em;
      line-height: 150%;
      color: $color-text--white;
    }

    p + p {
      margin-top: 2em;
    }
  }
}
