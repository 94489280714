@media (min-width: 1440px) and (max-width: 19200px) {
  body, html {
    width: 100%;
    overflow-x: hidden;
  }

  .container {
    padding: 0 5px;
  }

  body {
    font-size: 13px;
  }

  .swiper-slide__box {
    top: 8em;
  }

  .catalog-box__image {
    width: 100%;
  }

  .about-box {
    grid-gap: 0;
  }

  .articles-sec::before {
    height: 22em;
  }

  .contact-box__row {
    grid-gap: 200px;
  }

  .contact-sec::before {
    height: 66em;
  }

  .header {
    grid-gap: 30px;
  }


}

@media (min-width: 1200px) and (max-width: 1440px) {
  body, html {
    width: 100%;
    overflow-x: hidden;
  }

  .header {
    grid-gap: 30px;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  body, html {
    width: 100%;
    overflow-x: hidden;
  }

  .header-nav__item a,
  .tell-list__item a,
  .header-inner__top>h1 {
    font-size: 14px;
  }

  .header {
    grid-gap: 15px;
  }

  .header-inner__top {
    grid-gap: 15px;
  }

  .header-inner {
    grid-gap: 19px;
  }


  .about-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    grid-gap: initial;
  }

  .about-box__image {
    max-width: 60%;
  }

  .first-slider img {
    height: 55vw;
  }

  .swiper-slide__box {
    transform: translate(-50%, -50%);
    top: 50%;
  }


  // about

  // .about-sec--page {
  //   margin-top: 8em;
  // }

  .slider-sec {
    padding: 30px 0;
  }

  .big-pagination {
    margin-top: 20px;
    width: 80%;
    grid-gap: 12px;
  }



  // contact

  .contact-sec {
    margin-top: 10em;
  }

  .contact-box {
    grid-gap: 10vw 0;
    padding: 0 10vw;
  }

  .contact-box__row {
    grid-gap: 50px;
  }

  .contact-box__item {
    max-width: 48%;
    min-width: 48%;
  }

  .contact-sec::before {
    height: 60em;
  }

  .contact-box__item h2 {
    margin-bottom: 20px;
  }

  .subcatalog-box .more-list {
    // display: grid !important;
    grid-template-columns: repeat(3, 32%);
    grid-gap: 20px 10px;
    // justify-content: center;
    width: 100%;

    .more-list__item {
      max-width: 32%;
    }
  }






}

@media (min-width: 768px) and (max-width: 992px) {
  body, html {
    width: 100%;
    overflow-x: hidden;
  }

  .about-box {
    grid-gap: 1em;
  }

  .first-slider img {
    height: 80vw;
  }

  .swiper-slide__box {
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .header-nav__item a,
  .tell-list__item a,
  .header-inner__top>h1 {
    font-size: 14px;
  }

  .header {
    grid-gap: 15px;
  }

  .header-inner__top {
    grid-gap: 15px;
  }

  .header-inner {
    grid-gap: 19px;
  }

  .about-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    grid-gap: initial;
  }

  .about-box__image {
    max-width: 60%;
  }

  footer .wave-box {
    top: -10em;
  }


  //  404

  .main-box > p {
    font-size: 50vw;
  }

  .main-box {
    padding: 5em 0 10em;
  }


  .main-box__search input {
    text-indent: 10px;
    height: 60px;
  }

  .main-sec {
    margin-top: 6em;
  }

  .wave-box {
    height: 10em;
    top: -10em;
  }



  // about

  .about-sec--page {
    margin-top: 8em;
  }

  .slider-sec {
    padding: 30px 0;
  }

  .big-pagination {
    margin-top: 20px;
    width: 80%;
    grid-gap: 12px;
  }

    // article

  .articles-sec::before {
    height: 20em;
  }

  .article-box__item:first-child {
    margin-bottom: 35px;
  }

  .article-box__item:not(:last-child) {
    margin-bottom: 35px;
  }

  .article-box__image, .article-box__text {
    margin-bottom: 30px;
  }

  .more-list__item {
    flex-shrink: 0;
  }

  .more-list {
    width: calc(100% + 5px);
    overflow-x: scroll;
    margin-right: -5px;
  }

  .article-box {
    padding-bottom: 415px;

    div.text-box {
      position: absolute;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);

    }
  }

  .articles-sec {
    margin-top: 8em;
    padding-bottom: 9em;
  }


    //  articles

  .articles-box__bottom {
    padding: 20px 30px 30px;
  }

  .articles-box__bottom h3 {
    margin-bottom: 20px;
  }

  .articles-box__bottom a {
    padding: 10px 0;
  }


  .catalog-single {
    margin-top: 8em;
    padding-bottom: 100px;
  }


  // contact

  .contact-box {
    grid-gap: 17vw 0;
    padding: 0 10vw;
  }

  .contact-box__row {
    grid-gap: 50px;
  }

  .contact-box__item {
    max-width: 48%;
    min-width: 48%;
  }

  .contact-sec::before {
    height: 60em;
  }

  .contact-box__item h2 {
    margin-bottom: 20px;
  }


  //product
  .product-sec {
    margin-top: 8em;
  }

  .service-sec {
    margin-top: 8em;
  }

  .subcatalog-box .more-list {
    // display: grid !important;
    grid-template-columns: repeat(3, 32%);
    grid-gap: 20px 10px;
    // justify-content: center;
    width: 100%;

    .more-list__item {
      max-width: 32%;
    }
  }








}

@media (min-width: 576px) and (max-width: 768px) {

  body, html {
    width: 100%;
    overflow-x: hidden;
  }


  // header

  .header-nav, .tell-list, .header-inner__form{
    display: none;
  }

  .header {
    // flex-direction: column;
    padding: 10px 0;
  }

  .header__logo {
    margin-bottom: 15px;
  }

  .header-inner__top > h1 {
    text-align: center;
    font-size: 3vw;
  }

  // header

  .catalog-box__image {
    margin-bottom: 0px;
  }

  .catalog-box__item > h3 {
    font-size: 13px;
  }

  .catalog-box {
    grid-gap: 25px;
  }

  .first-slider img {
    height: 100vw;
  }

  .swiper-slide__box {
    width: 80%;
    top: 20%;
  }

  .first-slider__button svg  {
    width: 4vw;
  }

  .first-slider__button--next {
    right: 15px;
  }

  .first-slider__button--prev {
    left: 15px;
  }

  .about-box {
    grid-gap: 20px;
    grid-template-columns: 40% auto;
  }

  .about-sec {
    padding: 25px 0;
  }

  .icon-sec {
    padding: 30px 0 70px;
  }


  footer .wave-box {
    top: -7em;
  }


  //  404

  .main-box > p {
    font-size: 50vw;
  }

  .main-box {
    padding: 5em 0 10em;
  }


  .main-box__search input {
    text-indent: 10px;
    height: 60px;
  }

  .main-sec {
    margin-top: 6em;
  }

  .wave-box {
    height: 10em;
    top: -9.5em;
  }

  // about

  .about-sec--page {
    margin-top: 8em;
  }

  .slider-sec {
    padding: 30px 0;
  }

  .big-pagination {
    margin-top: 20px;
    width: 80%;
    grid-gap: 12px;
  }


  // article

  .articles-sec::before {
    height: 20em;
  }

  .article-box__item:first-child {
    margin-bottom: 35px;
  }

  .article-box__item:not(:last-child) {
    margin-bottom: 35px;
  }

  .article-box__image, .article-box__text {
    margin-bottom: 30px;
  }

  .more-list__item {
    flex-shrink: 0;
  }

  .more-list {
    width: calc(100% + 5px);
    overflow-x: scroll;
    margin-right: -5px;
  }

  .article-box {
    padding-bottom: 415px;

    div.text-box {
      position: absolute;
      width: 100%;
      left: 5px;
    }
  }

  .articles-sec {
    margin-top: 8em;
    padding-bottom: 8em;
  }

    //  articles

  .articles-box__bottom {
    padding: 20px 15px 30px;
  }

  .articles-box__bottom h3 {
    margin-bottom: 20px;
  }

  .articles-box__bottom a {
    padding: 10px 0;
  }


  .catalog-single {
    margin-top: 8em;
    padding-bottom: 100px;
  }


  //contact

  .contact-box {
    grid-gap: 120px 0;
  }

  .contact-box__row {
    grid-gap: 20px;
  }

  .contact-box__item {
    max-width: 48%;
    min-width: 48%;
  }

  .contact-sec::before {
    height: 61em;
  }

  .contact-box__item h2 {
    margin-bottom: 20px;
  }

  .contact-sec {
    margin-top: 8em;
    padding-bottom: 8em;
  }

  //product
  .product-sec {
    margin-top: 8em;
  }

  .product-sec::before {
    height: 60em;
  }



  .service-sec {
    margin-top: 8em;
  }

  .service-box__links {
    grid-gap: 20px;
  }

  .subcatalog-box .more-list {
    // display: grid !important;
    grid-template-columns: repeat(3, 32%);
    grid-gap: 20px 10px;
    // justify-content: center;
    width: 100%;

    .more-list__item {
      max-width: 32%;
    }
  }








}

@media (min-width: 280px) and (max-width: 576px) {
  body, html {
    width: 100%;
    overflow-x: hidden;
  }


// header

  .header-nav, .tell-list, .header-inner__form{
    display: none;
  }

  .header {
    flex-direction: column;
    padding: 10px 0;
  }

  .header__logo {
    margin-bottom: 15px;
  }

  .header-inner__top>h1 {
    text-align: center;
    font-size: 6vw;
  }


// header




  .first-slider img {
    height: 120vw;
  }

  .swiper-slide__box {
    width: 80%;
    top: 20%;
  }

  .first-slider__button svg  {
    width: 20px;
  }

  .swiper-slide__box > h2 {
    margin: 0 auto;
    font-size: 7.5vw;
    line-height: 100%;
    margin-bottom: 20px;
  }

  .swiper-slide__box > p {
    font-size: 4.3vw;
    line-height: 120%;
    margin-bottom: 25px;
  }

  .more {
    height: 50px;
  }

  .first-slider__button--next {
    right: 5px;
  }

  .first-slider__button--prev {
    left: 5px;
  }

  .wave-box {
    height: 10em;
    top: -10em;
  }

  .catalog-sec {
    padding-bottom: 40px;
  }

  .catalog-box {
    grid-template-columns: repeat(2, 48%);
    justify-content: space-between;
    grid-gap: 25px 0;

    &__image {
      margin-bottom: 10px;
    }

    &__item > h3 {
      font-size: 3vw;
    }
  }

  .about-sec {
    padding: 40px 0;
  }

  .about-box {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    &__descr {
      max-width: 100%;

      & > h2 {
        margin-bottom: 30px;
      }
    }
  }

  .icons-box {
    justify-content: space-around;
    grid-gap: 45px 0;
    grid-template-columns: repeat(2,47%);

    &__item p {
      font-size: Max(4vw, 12px);
    }

    &__item h3 {
      font-size: 30px;
    }
  }

  footer .wave-box {
    top: -10em;
  }

  .icon-sec {
    padding: 5em 0 10em;
  }

  .footer__bottom {
    flex-direction: column;
  }

  .footer__item {
    width: 100%;
  }

  footer {
    padding-bottom: 30px;
  }


  //  404

  .main-box > p {
    font-size: 50vw;
  }

  .main-box {
    padding: 5em 0 10em;
  }


  .main-box__search input {
    text-indent: 10px;
    height: 60px;
  }

  .main-sec {
    margin-top: 8em;
  }


  // about

  .about-sec--page {
    margin-top: 8em;
  }

  .slider-sec {
    padding: 30px 0;
  }

  .big-pagination {
    margin-top: 20px;
    width: 80%;
    grid-gap: 12px;
  }

  .crumbs {
    flex-wrap: wrap;
  }



  // article

  .articles-sec {
    margin-top: 8em;
  }

  .article-box {
    flex-wrap: wrap;
  }

  .article-box__item:first-child {
    margin-bottom: 50px !important;
  }

  .articles-sec::before {
    height: 230px;
  }

  .article-box__item:not(:last-child) {
    margin-bottom: 40px;
  }

  .more-list__item {
    flex-shrink: 0;
  }

  .more-list {
    width: calc(100% + 5px);
    overflow-x: scroll;
    margin-right: -5px;
  }




  //  articles


  .articles-box {
    display: flex;
    flex-direction: column;
  }


  .articles-box__bottom {
    padding: 20px 15px 30px;
  }

  .articles-box__bottom h3 {
    margin-bottom: 20px;
  }

  .articles-box__bottom a {
    padding: 10px 0;
  }


  .catalog-single {
    margin-top: 8em;
    padding-bottom: 100px;
  }


  .contact-box {
    grid-gap: 50px;
  }


  .contact-box__row, .contact-box__row:nth-child(even) {
    flex-direction: column;
    grid-gap: 20px;
  }

  .contact-box__item h2 {
    margin-bottom: 20px;
  }

  .contact-sec {
    margin-top: 8em;
  }

  .partners-box {
    padding-top: 20px;
    grid-gap: 30px 0;
    grid-template-columns: repeat(4,22%);
  }

  //product


  .product-sec {
    margin-top: 8em;
  }

  .product-sec::before {
    height: 43em;
  }

  .service-sec {
    margin-top: 8em;
  }

  .service-box__links {
    grid-gap: 20px;
  }

  .subcatalog-box .more-list {
    display: grid !important;
    grid-template-columns: repeat(2, 48%);
    grid-gap: 20px 10px;
    justify-content: center;
    width: 100%;

    .more-list__item {
      max-width: 100%;
    }
  }



}



@media screen and  (max-width: 356px) {
  .service-sec {
    .catalog-box__item>h3 {
      color: #fff;
    }
  }
}


