body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: normal;
  font-family: "Poppins";
  color: #000000;
  background-color: #D0DFEF;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: initial;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  width: 100%;
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.panel {
  z-index: 20;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.select__body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  z-index: 20;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
}

.select.is-active .select__body {
  max-height: 500px;
  height: auto;
}

.select.is-active {
  .select__icon svg {
    transform: translateY(-50%) scaleY(-1);
  }
}

section {
  position: relative;
  background-color: #fff;
}

.container {
  width: 100%;
  max-width: 117em;
  margin: 0 auto;
  padding: 0 5px;

  &--header {
    max-width: 144em;
  }

  &--slider {
    padding: 0;
    max-width: 100%;
  }
}






