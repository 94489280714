.catalog-sec {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(0, 109, 218, 1) 70%);
  position: relative;
  padding-bottom: 100px;
}

.catalog-single {
  margin-top: 15em;
  padding-bottom: 15em;
}
