footer {
  background: $bg--footer;
  position: relative;
  padding-bottom: 7.4em;

  .wave-box {
    top: -11em;

  }
}


.footer {
  &__top {
    display: flex;
    justify-content: center;
    margin-bottom: 3.5em;
    position: relative;
    z-index: 10;
  }

  &__bottom {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    grid-gap: 4.2em;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;

    &:first-child {
      flex-grow: 1;
      width: 100%;

      ul {
        max-width: 40em;
      }
    }

    h3 {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4em;
      line-height: 157%;
      text-align: center;
      letter-spacing: 0.35em;
      text-transform: uppercase;
      color: $color-main--title;
    }
  }
}
