.text-sec {
  padding:  0 0 15em;
}

.text-box {
  h2 {
    position: relative;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 3.2em;
    line-height: 130%;
    color: $color-text;
    margin-bottom: 60px;

    &::before {
      content: '';
      position: absolute;
      max-width: 136px;
      width: 100%;
      height: 5px;
      background-color: currentColor;
      left: 0;
      bottom: -10px;
    }
  }

  p {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 157%;
    color: $color-catalg--title;
  }

  p + p {
    margin-top: 25px;
  }
}
