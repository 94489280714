.contact-sec {
  margin-top: 15em;
  position: relative;
  padding-bottom: 15em;

  &::before {
    position: absolute;
    height: 83em;
    width: 100%;
    content: '';
    background: #006DDA;
    top: 0;

  }
}


.contact-box__image img {
  width: initial;
}

.contact-box {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  grid-gap: 200px 0;

  &__inner {
    max-width: 57em;
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: center;
    grid-gap: 130px;


    &:nth-child(even) {
      flex-direction: row-reverse;

      h2 {
        color: #006DDA;
      }

      a {
        color: #006DDA;
      }
    }
  }

  &__item {
    min-width: 370px;
  }

  &__item h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    color: $color-text--white;
    margin-bottom: 60px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      max-width: 136px;
      width: 80%;
      height: 5px;
      background-color: currentColor;
      left: 0;
      bottom: -10px;
    }
  }

  &__list {

    li + li {
      margin-top: 30px;
    }

    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.35em;
      color: #F0AD3E;
    }

    a {
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-text--white;
      display: inline;
    }
  }
}
