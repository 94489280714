.footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 30em;
  width: 100%;
  grid-gap: 0 5px;
  margin-top: 1.3em;

  &__li a {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 160%;
    text-align: center;
    color: $color-text--white;
  }
}
